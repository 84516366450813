import React, { useCallback, useState } from 'react';
import { useWtrDispatch, useWtrSelector } from 'redux/hooks';

import { createShoppingList } from 'redux/modules/shopping-lists/actions/create-shopping-list';

import {
  getListsModalLineNumber,
  getShoppingListsCreating,
} from 'redux/modules/shopping-lists/selectors';

import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import { EditNotesAdd } from '@johnlewispartnership/wtr-ingredients/foundations/icons';

import CreateListForm from 'components/Lists/CreateList/Form';
import type { CreateListFormData } from 'components/Lists/CreateList/Form/Form';

export interface CreateListProps {
  buttonLabel?: string;
}

const BUTTON_ICON = <EditNotesAdd />;

const CreateList = ({ buttonLabel = '' }: CreateListProps) => {
  const dispatch = useWtrDispatch();
  const [showForm, setShowForm] = useState(false);

  const creating = useWtrSelector(getShoppingListsCreating);
  const lineNumber = useWtrSelector(getListsModalLineNumber);

  const onSubmit = useCallback(
    (values: CreateListFormData) => {
      dispatch(
        createShoppingList({
          lineNumber,
          name: values.listName,
        }),
      ).then(() => setShowForm(false));
    },
    [dispatch, lineNumber],
  );

  const onButtonClick = useCallback(() => setShowForm(true), []);

  return showForm ? (
    <CreateListForm disabled={creating} onSubmit={onSubmit} />
  ) : (
    <Button
      data-testid="create-list-button"
      label={buttonLabel}
      onClick={onButtonClick}
      startIcon={BUTTON_ICON}
      theme="secondary"
      width="fit"
    />
  );
};

export default CreateList;
