import { reduxForm } from 'redux-form';

import CreateListForm, {
  CreateListFormData,
  CreateListFormProps,
} from 'components/Lists/CreateList/Form/Form';

export default reduxForm<CreateListFormData, Omit<CreateListFormProps, 'handleSubmit'>>({
  form: 'ListsModalCreateList',
  initialValues: {
    listName: '',
  },
})(CreateListForm);
