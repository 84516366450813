import shoppinglists from 'api/definitions/shoppinglists';

import { SHOPPING_LISTS_CREATE_ACTIONS } from 'redux/modules/shopping-lists/actions/types';

const createListItem = (lineNumber: string | null) => {
  let lineNumbersArr = [];
  if (lineNumber?.includes(',')) {
    lineNumbersArr = lineNumber.split(',');

    const toAddAll = lineNumbersArr.map(ln => ({
      lineNumber: ln,
      quantity: {
        amount: 1,
        uom: 'C62',
      },
    }));

    return toAddAll;
  }

  return {
    lineNumber,
    quantity: {
      amount: 1,
      uom: 'C62',
    },
  };
};

export const createShoppingList = ({
  name,
  lineNumber,
}: {
  name: string;
  lineNumber: string | null;
}) => {
  const listItem = lineNumber?.includes(',')
    ? createListItem(lineNumber)
    : [createListItem(lineNumber)];

  return {
    types: SHOPPING_LISTS_CREATE_ACTIONS,
    apiCall: shoppinglists.post({
      body: {
        items: lineNumber ? listItem : [],
        name,
      },
      iHandleStatusCodes: true,
    }),
  };
};
